import React from 'react'
import {VBSArchiveContext, SELECT_COUNTRY, SELECT_REGION, SELECT_CROP} from 'components/vbs/actions'
import {StyledSelect} from 'components/select'
import Styles from './filters.module.css'


const selectAllRegionsOption = {value:null, label:"All Regions", isSelected:false }
const selectAllCountriesOption = {value:null, label:"All Countries", isSelected:false }
const selectAllCropsOption = {value:null, label:"All Crops", isSelected:false }

const filterToOption = (filter, selectAllOption) => {
  if(!filter){
    return selectAllOption
  }
  return {
    value: filter.id,
    label: filter.name,
    isSelected: false
  }
}

export const VBSFilters = (props) => {
  const {state, dispatch} = React.useContext(VBSArchiveContext)
  return (
    <div id="vbsFilters" className={Styles.container}>
      <div id="vbsRegionFilter" className={Styles.filter}>
        <StyledSelect
          value={filterToOption(state.region, selectAllRegionsOption)}
          choices={[selectAllRegionsOption].concat(state.availableRegions.map(r=>{return filterToOption(r, selectAllRegionsOption)}))}
          onChange={(value)=>{dispatch({type:SELECT_REGION, value:value.value})}} />
      </div>
      {state.availableCountries.length>0 && 
      <div id="vbsCountryFilter" className={Styles.filter}>
        <StyledSelect
          value={filterToOption(state.country, selectAllCountriesOption)}
          choices={[selectAllCountriesOption].concat(state.availableCountries.map(r=>{return filterToOption(r, selectAllCountriesOption)}))}
          onChange={(value)=>{dispatch({type:SELECT_COUNTRY, value:value.value})}}
        />
       </div>
      }
      {state.availableCrops.length>0 && 
       <div id="vbsCropsFilter" className={Styles.filter}>
         <StyledSelect
           value={filterToOption(state.crop, selectAllCropsOption)}
           choices={[selectAllCropsOption].concat(state.availableCrops.map(r=>{return filterToOption(r, selectAllCropsOption)}))}
           onChange={(value)=>{dispatch({type:SELECT_CROP, value:value.value})}}
         />
       </div>
      }
    </div>

  )
}
