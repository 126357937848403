import React from 'react'
import {useStaticQuery, graphql} from 'gatsby'

export const withAllVbs = () => {
  const {allVbs} = useStaticQuery(graphql`
    query allVbs {
      allVbs: allWordpressWpVbs(sort:{fields:menu_order}){
        nodes{
          id
          path
          title
          regions {
            name
            id
          }
          countries {
            name
            id
            parent_element {
               id
            }
          }
          crops {
            name:title
            id
          }
          vbs_types {
            name
            id
          }
           featured_media  {
            localFile {
              childImageSharp {
                fluid ( maxWidth:370){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }

        }
      }
    }`)
  return [].concat(allVbs.nodes);
}
