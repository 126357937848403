import React from "react";
import {useStaticQuery, graphql} from 'gatsby'


export const withAllVbsLocations = () => {
  const {countries, regions} = useStaticQuery(graphql`query AllVbsLocationsQuery{
      countries: allWordpressWpVbsLocation(filter:{wordpress_parent:{ne:0}, count:{gt:0}}){
        nodes{
          id
          name
          wordpress_parent
          count
          vbs{
            id
          }
          parent_element{
           id
          }
        }
      }
      regions: allWordpressWpVbsLocation(filter:{wordpress_parent:{eq:0}, count:{gt:0}}){
        nodes{
          id
          name
          count
          vbs{
            id
          }
        }
      }
    } `)
  return {regions:regions.nodes, countries:countries.nodes}
}

