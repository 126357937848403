import {createContext} from 'react'
export const SELECT_REGION = "SELECT_REGION"
export const SELECT_COUNTRY = "SELECT_COUNTRY"
export const SELECT_CROP = "SELECT_CROP"
export const SELECT_CATEGORY = "SELECT_CATEGORY"
export const UPDATE_ITEMS = "UPDATE_ITEMS"


export const stateSchema = {
  allItems: [],
  allCategories: [],
  allRegions: [],
  allCountries: [],
  allCrops: [],
  availableRegions: [],
  availableCountries: [],
  availableCrops: [],
  category: null,
  country: null,
  region: null,
  crop: null,
  items: []
}



const reduceFilters = (raw) => {
  const ids = raw.map(e=>e.id)
  return raw.filter((e,index) => {
    return index === ids.indexOf(e.id)
  })
}


const updateItems = (state) => {
  let items = []
  let availableRegions = []
  let availableCountries = []
  let availableCrops = []
  //filter list of items by category
  if(state.category){
    items = state.allItems.filter(item => [].concat(item.vbs_types.map(c=>c.id)).includes(state.category))
    availableRegions = reduceFilters([].concat(...items.map(item=>item.regions || [])))
    availableCountries = reduceFilters([].concat(...items.map(item=>item.countries || [])))
    availableCrops = reduceFilters([].concat(...items.map(item=>item.crops || [])))
  }else{
    items = [...state.allItems]
    availableRegions= [...state.allRegions]
    availableCountries=[...state.allCountries]
    availableCrops=[...state.allCrops]
  }

  //filter list of items by regions
  if(state.region){
    const region_items = [].concat(state.region.vbs.map(vbs=>vbs.id)).filter(e=>e)
    items = items.filter(item => region_items.includes(item.id))
    availableCountries = reduceFilters([].concat(...items.map(item=>item.countries || []))).filter(country=>country.parent_element.id===state.region.id)
  }
  if(state.country){
    const country_items= [].concat(state.country.vbs.map(vbs=>vbs.id)).filter(e=>e)
    items = items.filter(item => country_items.includes(item.id))
    availableCrops = reduceFilters([].concat(...items.map(item=>item.crops || [])))

  }
  if(state.crop){
    items = items.filter(item=> item.crops && item.crops.length && item.crops.map(c=>c&&c.id).includes(state.crop.id))

  }

  return {
    ...state,
    items:items,
    availableRegions: availableRegions,
    availableCountries: availableCountries,
    availableCrops: availableCrops
  }
}
export const reducer = (state, action)=>{
  switch(action.type){
  case SELECT_CATEGORY:
    return updateItems({
      ...state,
      region: null,
      country: null,
      crop: null,
      category: action.value
    })
  case SELECT_REGION:
    return updateItems(
      {...state,
       region:state.allRegions.find(r=>r.id===action.value),
       country: null,
       crop: null
      })
  case SELECT_COUNTRY:
    return updateItems({
      ...state,
      crop: null,
      country: state.allCountries.find(r=>r.id===action.value)
    })
  case SELECT_CROP:
    return updateItems({
      ...state,
      crop: state.allCrops.find(c=>c.id===action.value)
    })

  case UPDATE_ITEMS:
    return updateItems(...state)
  default:
    return state

  }
}
export const VBSArchiveContext = createContext(null);
