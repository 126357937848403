import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout'
import {DoubleCollaborationWidget} from 'components/collaboration/doubleCollab.js'
import Section from 'components/section'
import {VBSArchive} from 'components/vbs/archive'
import {TitleBanner} from 'components/banners/titleBanner'

const ArchiveVBSTemplate = ({data, ...props}) => (
  <Layout
    articleId={`${data.wordpressPage.type}-${data.wordpressPage.wordpress_id}`}
    articleClassName={`${data.wordpressPage.classes}`}
    context = {props.pageContext}
    location = {props.location}
  >
    <TitleBanner title={data.wordpressPage.title} content={data.wordpressPage.content} />
    <Section>
      <DoubleCollaborationWidget 
        projectRight={data.wordpressPage.doubleCollab.projectRight}
        projectLeft={data.wordpressPage.doubleCollab.projectLeft}
      />
    </Section>
    <Section>
      <VBSArchive/>
    </Section>
  </Layout>
);


export default ArchiveVBSTemplate
export const VBSQuery = graphql `
query VBSQuery($id: String!){
  wordpressPage(id:{eq:$id}){
    title
    content
    path
    type
    date
    wordpress_id
    classes
    doubleCollab{
      projectRight {
        title
        excerpt
        path
        featured_media {
          localFile {
            childImageSharp {
              fixed (height: 485, width: 505){
                ...GatsbyImageSharpFixed
              }
              fluid (maxWidth: 768){
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      projectLeft {
        title
        excerpt
        path
        featured_media {
          localFile {
            childImageSharp {
              fixed (height: 485, width: 505){
                ...GatsbyImageSharpFixed
              }
              fluid (maxWidth: 768){
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
}
`
