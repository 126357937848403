import React from 'react'
import ButtonStyles from './vbsCategoryButton.module.css'
import {VBSArchiveContext, SELECT_CATEGORY} from 'components/vbs/actions'

const VbsCategory = (props) => {
  return (
    <button className={`${ButtonStyles.button} ${props.isActive?ButtonStyles.activeButton:''}` } onClick={props.onClick}>
      {props.name}
  </button>
  )
}

export const VbsCategorySwitcher = (props) => {
  const {state, dispatch} = React.useContext(VBSArchiveContext);

  return (
    <div className={ButtonStyles.container}>
      <VbsCategory
        name='All Categories'
        onClick={()=>dispatch({type:SELECT_CATEGORY, value:null})}
        isActive={state.category===null}
      />
      {state.allCategories.map(filter=>
        <VbsCategory
          {...filter}
          onClick={()=>dispatch({type: SELECT_CATEGORY, value:filter.id})}
          isActive={state.category===filter.id}
          key={filter.id}
        />
      )}
    </div>
  )
}
