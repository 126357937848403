import React from 'react'
import {withGrid} from 'components/grid'
import {VbsCard} from './parts/card'
import {withAllVbs} from './hooks/allVbs'
import {withAllVbsLocations} from './hooks/allVbsLocations'
import {VBSFilters} from './parts/filters'
import {VbsCategorySwitcher} from './parts/category_filter'
import Styles from './archive.module.css'
import {stateSchema,
        reducer,
        VBSArchiveContext,
       } from './actions'


const reduceFilters = (raw) => {
  const ids = raw.map(e=>e.id)
  return raw.filter((e,index) => {
    return index === ids.indexOf(e.id)
  })
}


export const VBSArchive = (props) => {
  const allVbs = withAllVbs()
  const allLocations = withAllVbsLocations()
  const vbsCategories = reduceFilters([].concat(...allVbs.map(vbs=>vbs.vbs_types)).filter(n=>n))
  const allCrops = reduceFilters([].concat(...allVbs.map(vbs=>vbs.crops).filter(n=>n)))
  const initialState = {
    ...stateSchema,
    allItems: [...allVbs],
    allCategories: [...vbsCategories],
    allRegions: [...allLocations.regions],
    allCountries: [...allLocations.countries],
    allCrops: [...allCrops],
    availableRegions: [...allLocations.regions],
    availableCountries: [...allLocations.countries],
    availableCrops: [...allCrops],
    items: [...allVbs]
  }
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const VbsGrid = withGrid(VbsCard, state.items)
  return (
    <div>
      <VBSArchiveContext.Provider value={{state, dispatch}}>
        <VbsCategorySwitcher />
        <VBSFilters /> 
        <VbsGrid/>
      </VBSArchiveContext.Provider>
    </div>
    
  )
}
